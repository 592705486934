export class Employer{
  FirstName: string;
  LastName: string;
  CompanyName: string;
  Title: string;
  Email: string;
  Phone: string;
  PhoneType: number;
  IsInternationalPhone: boolean;
}
