import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyDtl } from '../model/company-dtl.model';
import { WidgetConfigParam } from '../model/widget-config-param';

import { HttpWrapperService } from './httpWrapper.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpWrapperService) { }

  GetCompanyDetails(param: WidgetConfigParam): Observable<CompanyDtl> {
    return this.http.post("/Account/GetCompanyDetails/", param)
      .pipe(map((res: CompanyDtl) => res));
  };

  GetCompanyStates(companyId: number): Observable<any> {
    return this.http.get("/Account/GetCompanyStates/" + companyId)
      .pipe(map((res: any) => res));
  };
}
