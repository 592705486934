export class WorkflowItem {
  ChatbotWorkItemId: number = 0;
  WorkItemText: string = "";
  IsQuestion: boolean = false;
  QuestionTypeId: number = 0;
  CompanyId: number = 0;
  GotoWorkItemId: number = 0;
  Options: WorkflowItemOption[] = [];
  UserResponse: string = "";
}

class WorkflowItemOption {

  ChatbotWorkItemOptionId: number = 0;
  OptionText: string = "";
  GotoWorkItemId: number = 0;

}

export class ChatSession {
  IsCandNew: boolean;
  IsJobConvExits: boolean;
  ChatSessionId: number;
  ChatApplicantId: number;
  ChatEmployerId: number;
  ChatType: string;

  QuesDone: QuesDone[];
  ScreenQuesDone: any[];

  ProfileState: number = ProfileStatus.BelowMinimum;
  IsWaitingStarted: boolean;
  IsWaitEnded: boolean;

  IsContinueProfile: boolean;
  //IsChatStarted: boolean;


}

export class QuesDone {
  QuesId: number;
}

export enum ProfileStatus {
  BelowMinimum = 0,
  Minimum = 1,
  Complete = 2,
}
